import React, { useRef } from 'react'
import { Button, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

function ContactForm() {
    const { t } = useTranslation();

    const [formStatus, setFormStatus] = React.useState('Send');
    const [hintStatus, setHintStatus] = React.useState('');

    const form = useRef();
    let btnRef = useRef();

    const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus('Submitting...');

    if(btnRef.current){
        btnRef.current.setAttribute("disabled", "disabled");
    };

    emailjs.sendForm('service_6r9mlek', 'template_q3pu3rw', form.current, '3JOqrgprywYNMTuOw')
      .then((result) => {
        //   console.log(result.text);
          setFormStatus('Sent!');
          setHintStatus('Sent Successfully.');
      }, (error) => {
        //   console.log(error.text);
          setHintStatus('Unable to send. Please try again later.');
      });
  };

  return (
    <Form onSubmit={onSubmit} ref={form}>
        <Form.Group className="mb-3" controlId="name">
            <Form.Label>{t('Name')}</Form.Label>
            <Form.Control type="text" name="user_name" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="email" >
            <Form.Label>{t('Email address')}</Form.Label>
            <Form.Control type="email" name="user_email" required />
            <Form.Text className="text-muted">
            {t('We\'ll never share your email with anyone else.')}
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="contact">
            <Form.Label>{t('Contact Number (Optional)')}</Form.Label>
            <Form.Control type="text" name="user_contact"/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="message">
            <Form.Label>{t('Message')}</Form.Label>
            <Form.Control as="textarea" rows={3} required name="message"/>
        </Form.Group>
      <Button variant="primary" type="submit" ref={btnRef}>
        {t(formStatus)}
      </Button>
        <p>{t(hintStatus)}</p>
    </Form>
  )
};
export default ContactForm