import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage/Homepage";
import NavBarComponent from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";
import ContactUs from "./Pages/ContactUs/ContactUs";
import AboutDCM from "./Pages/AboutDCM/AboutDCM";
import Services from "./Pages/Services/Services";
import TaxationCalendar from "./Pages/TaxationCalendar/TaxationCalendar";

let App = () => {
  return (
    <Router basename={"/"}>
      <NavBarComponent />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about-dcm" element={<AboutDCM />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/taxation-calendar" element={<TaxationCalendar />} />
        {/* <Route path="*" element={<NoPage />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
