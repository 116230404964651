import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './AboutDCM.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import about_message from '../../Assets/about_message.jpg';

function AboutDCM() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
  
        <Container >
                <Row className='about-container-b  justify-content-md-center'>
                    <Col xs={12} md={12} className='about-container-sub-b'>
                        <Row>
                            <span className='service-heading white-text'>{t('DCM: Tailored Software Solutions for SMEs')}</span>
                        </Row>
                        <Row>
                            <span className='service-details white-text'>{t("Empower your business with custom-built software that streamlines your workflows.")}</span>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Button 
                                    className='learnmore-btn' 
                                    variant="primary" 
                                    size="lg"
                                    onClick={() => navigate('/contact-us')}
                                >{t('Contact Us')}</Button>
                                
                                <Button 
                                    className='contactus-btn' 
                                    variant="outlined-primary" 
                                    size="lg"
                                    style={{backgroundColor: "black"}}
                                    onClick={() => navigate('/services')}
                                >{t('Learn More')}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>    
                <Row className="d-flex justify-content-md-center about-container-sub-b">
                    <Col xs={12} md={12}>
                        <Row className="justify-content-md-center card-contact-about">
                            <Col xs={12} md={12}>
                                <Card className='card-contact-panding'>
                                    <Card.Body>
                                        <Card.Title><span className='home-content-heading'>{t('Tailored Software Solutions')}</span></Card.Title>
                                        <Card.Title style={{marginTop: "30px"}}><span className='service-details'>{t('Our team specializes in developing standard software for small and medium enterprises (SMEs), then customizing it to perfectly fit your unique needs and workflows.')}</span></Card.Title>
                                        <Card.Title style={{marginTop: "30px"}}>
                                        <div class="container">
                                            <div class="main-timeline">
                                                <div class="timeline">
                                                    <div class="icon"></div>
                                                    <div class="date-content">
                                                        <div class="date-outer">
                                                            <span class="date">
                                                                <span class="year">1</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="timeline-content">
                                                        <h4 class="title">{t("Understand")}</h4>
                                                        <p class="description">
                                                            {t("We take the time to deeply understand your business and its challenges.")}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="timeline">
                                                    <div class="icon"></div>
                                                    <div class="date-content">
                                                        <div class="date-outer">
                                                            <span class="date">
                                                    
                                                            <span class="year">2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="timeline-content">
                                                        <h4 class="title">{t("Build")}</h4>
                                                        <p class="description">
                                                            {t("Our developers create a custom software solution built from the ground up for your company.")}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="timeline">
                                                    <div class="icon"></div>
                                                    <div class="date-content">
                                                        <div class="date-outer">
                                                            <span class="date">
                                            
                                                            <span class="year">3</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="timeline-content">
                                                        <h4 class="title">{t("Optimize")}</h4>
                                                        <p class="description">
                                                            {t("We continually refine and enhance the software to ensure maximum efficiency and productivity.")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='justify-content-md-center service-row'>
                            <span className='home-content-heading'>{t('Empowering SMEs')}</span>
                        </Row>
                        <Row className='justify-content-md-center' style={{paddingBottom: "30px"}}>
                            <span className='home-content'>{t('Our tailored solutions help small and medium enterprises (SMEs) save time, reduce manual tasks, and gain greater control over their operations.')}</span>
                        </Row>
                        <Row className='justify-content-md-center '>
                            <Col  xs={12} md={4} className="about-us-col col-ser" >
                                <span className='home-aboutus'>{t('Streamlined Workflows')}</span><br />
                                <span className='home-content'>{t('Customized features that perfectly align with your business processes.')}</span>
                            </Col>
                            <Col  xs={12} md={4} className="about-us-col col-ser">
                                <span className='home-aboutus'>{t('Increased Productivity')}</span><br />
                                <span className='home-content'>{t('Automate repetitive tasks and free up your team to focus on high-value work.')}</span>
                            </Col>
                            <Col  xs={12} md={4} className="about-us-col col-ser">
                                <span  className='home-aboutus'>{t('Scalable Growth')}</span><br />
                                <span className='home-content'>{t('Software that can adapt and expand as your business needs evolve.')}</span>
                            </Col>
                        </Row>
                        <Row className='justify-content-md-center service-row'>
                            <Col xs={12} md={7} className='Service-container'>
                                <Row>
                                    <span className='home-content-heading'>{t('Send Us a Message')}</span>
                                </Row>
                                <Row>
                                    <span className='service-details'>{t("Our dedicated team is here to assist you with any questions or concerns. We pride ourselves on providing personalized attention and timely responses.")}</span>
                                </Row>
                                <Row>
                                    <span className='service-details'>{t("Discuss your unique business needs with our experts. We'll work closely with you to develop the perfect software solution to power your success.")}</span>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Button 
                                            className='learnmore-btn' 
                                            variant="primary" 
                                            size="lg"
                                            onClick={() => navigate('/contact-us')}
                                        >{t('Contact Us')}</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={5} className='Service-img-container'>
                                <img  className='Service-img' src={about_message} alt="Service" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
               
        </Container>
 
    );
}

export default AboutDCM;