import React from 'react'
import './Footer.css';
import { useTranslation } from "react-i18next";
import pdf from '../../Assets/DCMPrivacyPolicy.pdf';

function Footer() {
    const { t } = useTranslation();

    return (
        <footer id="footer">
            <p id="copyright">{t('© Copyright 2024. Digital Corporate Management Co., Ltd. All Rights Reserved')}</p>
            <p><a href={pdf} target="_blank" rel="noreferrer" id="PPlink">{t('Our Privacy Policy')}</a></p>
        </footer>
    );
}

export default Footer;