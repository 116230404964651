import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './Services.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import dmeets_logo from '../../Assets/DMEETS.PNG';
import sme_logo from '../../Assets/SME.PNG';
import dtime_logo from '../../Assets/DTIME.PNG';
import service_us from '../../Assets/service1.jpg';
import dmeets_call from '../../Assets/dmeets_call.jpg';
import staper from '../../Assets/staper.png';


function Services() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 767); // ปรับขนาดที่ 600px ตามสมควร
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // เรียกใช้ฟังก์ชั่นเมื่อโหลดหน้าเว็บ
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (

            <Container className='service-container'>
                <Row className='justify-content-md-center'>
                    <Col xs={12} md={7} className='Service-container'>
                        <Row>
                            <span className='service-heading'>{t('Innovative Software Solutions for Your Business')}</span>
                        </Row>
                        <Row>
                            <span className='service-details'>{t("Unlock your business potential with our customizable software applications and expert support.")}</span>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Button 
                                    className='contactus-btn' 
                                    variant="outlined-primary" 
                                    size="lg"
                                    onClick={() => navigate('/contact-us')}
                                >{t('Contact Us')}</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={5} className='Service-img-container'>
                        <img  className='Service-img' src={service_us} alt="Service" />
                    </Col>
                </Row>
                <Row className='justify-content-md-center service-row'>
                    <span className='home-content-heading'>{t('Seamless Business Operations')}</span>
                    <Col  xs={12} md={4} className="about-us-col col-ser" >
                        <span className='home-aboutus'>{t('Streamlined Workflows')}</span><br />
                        <span className='home-content'>{t('Our agile software applications help you reduce business restrictions and effectively manage your time.')}</span>
                    </Col>
                    <Col  xs={12} md={4} className="about-us-col col-ser">
                        <span className='home-aboutus'>{t('Comprehensive Support')}</span><br />
                        <span className='home-content'>{t('We provide the necessary support to help you build and maintain your business with our customizable solutions.')}</span>
                    </Col>
                    <Col  xs={12} md={4} className="about-us-col col-ser">
                        <span  className='home-aboutus'>{t('Increased Productivity')}</span><br />
                        <span className='home-content'>{t('Boost your efficiency and focus on growth with our software designed to keep your business on track.')}</span>
                    </Col>
                </Row>
                <Row className='justify-content-md-center service-row ' >
                    <Col xs={12} md={7} className='Service-container'>
                        <Row>
                            <span className='home-content-heading'>{t('Dmeets: Secure Online Meetings')}</span>
                        </Row>
                        <Row>
                            <span className='service-details'>{t("Keep your online meetings safe and secure. Whether you're the host or a partscipant, Dmeets makes it simple and easy. Enjoy our online meeting platform for your business - and it starts ")} <b>{t('free.')}</b></span>
                        </Row>
                        <Row className='d-flex mt-4'>
                            <Col xs={2} md={2} >
                                <img className="app-logo" src={dmeets_logo} alt="dmeets-logo" />
                            </Col>
                            <Col xs={9} md={9} className='ling-web'>
                                <span className='home-content'>
                                    {t("Visit our ")}
                                    <a className="app-link" href="https://dmeets.dcm.co.th/">{t('Dmeets website')}</a>
                                    {t(" to learn more.")}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={5} className='Service-img-container'>
                        <img  className='Service-img' src={dmeets_call} alt="dmeets_call" />
                    </Col>
                </Row>
                <Row className='justify-content-md-center mb-5' style={{marginTop: "150px"}}>
                    <Col xs={10} md={10}><span className='home-content-heading'>{t('SME Mate: Empower Your SME')}</span></Col>
                    <Col xs={2} md={2}><img className="app-logo-right" src={sme_logo} alt="sme-logo" /></Col>
                        
                </Row>
                <Row>
                    <Col  xs={12} md={4} className="about-us-col col-ser" >
                        <Row>
                            <Col xs={1} md={2}>
                                <i className="bi bi-1-square-fill home-aboutus-number"></i>
                            </Col>
                            <Col xs={11} md={10} className='home-aboutus-number-descript'> 
                                <Row>
                                    <span className='home-aboutus'>{t('Streamlined Workflows')}</span>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                <span className='service-content'>{t('Manage your business on-the-go with our intuitive web and mobile applications.')}</span>
                                </Row>
                                
                                
                            </Col>
                            
                        </Row>
                    </Col>
                    <Col  xs={12} md={4} className="about-us-col col-ser" >
                        <Row>
                            <Col xs={1} md={2}>
                                <i className="bi bi-2-square-fill home-aboutus-number"></i>
                            </Col>
                            <Col xs={11} md={10} className='home-aboutus-number-descript'> 
                                <Row>
                                    <span className='home-aboutus'>{t('Optimized Operations')}</span>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <span className='service-content'>{t("Empower your SME's success by streamlining and enhancing your core business processes.")}</span>
                                </Row>
                            </Col>
                            
                        </Row>
                    </Col>
                    <Col  xs={12} md={4} className="about-us-col col-ser" >
                        <Row>
                            <Col xs={1} md={2}>
                                <i className="bi bi-3-square-fill home-aboutus-number"></i>
                            </Col>
                            <Col xs={11} md={10} className='home-aboutus-number-descript'> 
                                <Row>
                                    <span className='home-aboutus'>{t('All-in-One Platform')}</span>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <span className='service-content'>{t('Consolidate your document workflows and operations with our comprehensive, integrated platform.')}</span>
                                </Row>
                            </Col>
                            
                        </Row>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <span className='home-content'>
                        {t("Check out our full service at ")}
                        <a className="app-link" href="https://smemate.dcm.co.th/home">{t('SME MATE.')}</a>
                    </span>
                </Row>
                <Row className='justify-content-md-center dtime-row'>
                    <Col xs={12}>
                        <Row >
                            <Col xs={2} md={1}><img className="app-logo-dtime" src={dtime_logo} alt="dtime-logo" /></Col>
                            <Col xs={10} md={11} className='ditem-letf'><span className='home-content-heading logo-dtime'>{t('Streamline Your Business with DTime')}</span></Col>
                        </Row>
                        <Row style={{ paddingTop: "20px"}}>
                            <Col xs={12}>
                                <span className='home-content'>
                                    {t("Efficient Business Management Software for Growing Companies")}
                                </span>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "30px"}}>
                            <Col xs={12}>
                                <span className='service-content-sub-heading'>{t('Key Features of DTime:')}</span>
                            </Col>
                        </Row>
                        <Row className='justify-content-md-center home-aboutus-row' style={{ paddingTop: "20px"}}>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={1} md={1}>
                                    <i className="bi bi-1-square-fill home-aboutus-number"></i>
                                    </Col>
                                    <Col xs={11} md={11} className='home-aboutus-number-descript'> 
                                    <Row className='number-span'>
                                        <span className='home-content-subheading'>{t('Leave Requests')}</span>
                                    </Row>
                                    <Row>
                                        <span className='service-content'>{t('Automate and manage leave applications with ease. Approve requests, track balances, and maintain records effortlessly.')}</span>
                                    </Row>
                                    </Col>
                                </Row>
                                </Col>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={1} md={1}>
                                    <i className="bi bi-2-square-fill home-aboutus-number"></i>
                                    </Col>
                                    <Col xs={11} md={11} className='home-aboutus-number-descript'>
                                    <Row className='number-span'>
                                        <span className='home-content-subheading'>{t('Timesheet Management')}</span>
                                    </Row>
                                    <Row>
                                        <span className='service-content'>{t('Simplify time tracking with automated timesheet entries and approvals to ensure accurate payroll.')}</span>
                                    </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center home-aboutus-row">
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={1} md={1}>
                                    <i className="bi bi-3-square-fill home-aboutus-number"></i>
                                    </Col>
                                    <Col xs={11} md={11} className='home-aboutus-number-descript'>
                                    <Row className='number-span'>
                                        <span className='home-content-subheading'>{t('Invoicing')}</span>
                                    </Row>
                                    <Row>
                                        <span className='service-content'>{t('Generate and manage invoices quickly, improving cash flow with timely and professional billing.')}</span>
                                    </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={1} md={1}>
                                    <i className="bi bi-4-square-fill home-aboutus-number"></i>
                                    </Col>
                                    <Col xs={11} md={11} className='home-aboutus-number-descript'>
                                    <Row className='number-span'>
                                        <span className='home-content-subheading'>{t('Receipt Management')}</span>
                                    </Row>
                                    <Row>
                                        <span className='service-content'>{t('Easily create and organize receipts, keeping your financial records accurate and audit-ready.')}</span>
                                    </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center home-aboutus-row">
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={1} md={1}>
                                    <i className="bi bi-5-square-fill home-aboutus-number"></i>
                                    </Col>
                                    <Col xs={11} md={11} className='home-aboutus-number-descript'>
                                    <Row className='number-span'>
                                        <span className='home-content-subheading'>{t('Sales Orders')}</span>
                                    </Row>
                                    <Row>
                                        <span className='service-content'>{t('Automate sales order processing to enhance accuracy and customer satisfaction, while integrating seamlessly with inventory systems.')}</span>
                                    </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={1} md={1}>
                                    <i className="bi bi-6-square-fill home-aboutus-number"></i>
                                    </Col>
                                    <Col xs={11} md={11} className='home-aboutus-number-descript'>
                                    <Row className='number-span'>
                                        <span className='home-content-subheading'>{t('Customization')}</span>
                                    </Row>
                                    <Row>
                                        <span className='service-content'>{t('Tailor DTime to meet your specific business needs, enhancing functionality as your business grows.')}</span>
                                    </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "30px"}}>
                            <Col xs={12}>
                                <span className='service-content-sub-heading'>{t('Why Choose DTime?')}</span>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "30px"}}>
                            <Col  xs={12} md={4} className="service-us-col" >
                                <Card className='card-service-dtime'>
                                    <Card.Body>
                                        <Row>
                                            <span className='home-aboutus'>{t('Boost Efficiency')}</span>
                                        </Row>
                                        <Row style={{marginTop: "10px"}}>
                                        <span className='service-content'>{t('Automate routine tasks, reduce manual errors, and focus on strategic goals.')}</span>
                                        </Row>   
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col  xs={12} md={4} className="service-us-col" >
                                <Card className='card-service-dtime'>
                                    <Card.Body>
                                        <Row>
                                            <span className='home-aboutus'>{t('Increase Transparency')}</span>
                                        </Row>
                                        <Row style={{marginTop: "10px"}}>
                                        <span className='service-content'>{t('Gain real-time insights into your business operations for better decision-making.')}</span>
                                        </Row>   
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col  xs={12} md={4} className="service-us-col" >
                                <Card className='card-service-dtime'>
                                    <Card.Body>
                                        <Row>
                                            <span className='home-aboutus'>{t('Scale with Ease')}</span>
                                        </Row>
                                        <Row style={{marginTop: "10px"}}>
                                        <span className='service-content'>{t('Support your business growth with scalable solutions that handle increasing complexity.')}</span>
                                        </Row>   
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "20px"}}>
                            <Col xs={12}>
                                <span className='service-content'>
                                    {t("Empower Your Business Today with DTime – The Smart Way to Manage Your Business Operations!")}
                                </span>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "20px"}}>
                            <Col xs={12}>
                            <span className='home-content'>             
                                <a className="app-link" href="https://dtimeadmin.dcm.co.th/register-company">{t('Register')}</a>
                                {t(" your company and start using DTime!")}
                            </span>
                            </Col>
                        </Row>
                    </Col>
  
                </Row>
                <Row className='justify-content-md-center ' style={{marginTop: "150px"}}>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} md={12}><span className='home-content-heading'>{t('Why DCM?')}</span></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='justify-content-md-center' style={{marginTop: "20px"}}>
                    <div class="container">
                        <div class="main-timeline">
                            <div class="timeline">
                                <div class="icon"></div>
                                <div class="date-content">
                                    <div class="date-outer">
                                        <span class="date">
                                            <span class="year">1</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="timeline-content">
                                    <h5 class="title">{t("Expertise")}</h5>
                                    <p class="description">
                                        {t("Our team of skilled professionals has the expertise to deliver tailored software solutions.")}
                                    </p>
                                </div>
                            </div>

                            <div class="timeline">
                                <div class="icon"></div>
                                <div class="date-content">
                                    <div class="date-outer">
                                        <span class="date">
                                
                                        <span class="year">2</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="timeline-content">
                                    <h5 class="title">{t("Customization")}</h5>
                                    <p class="description">
                                        {t("We work closely with clients to understand their unique needs and provide customizable applications.")}
                                    </p>
                                </div>
                            </div>

                            <div class="timeline">
                                <div class="icon"></div>
                                <div class="date-content">
                                    <div class="date-outer">
                                        <span class="date">
                        
                                        <span class="year">3</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="timeline-content">
                                    <h5 class="title">{t("Continuous Innovation")}</h5>
                                    <p class="description">
                                        {t("We are committed to ongoing development to ensure our software remains cutting-edge.")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
                <Row className=" justify-content-md-center card-contact">
                    <Card className='card-contact-panding'>
                    <Card.Body>
                        <Card.Title><span className='home-content-heading'>{t('Start Transforming Your Business Today')}</span></Card.Title>
                        {
                            !isMobile ? (
                                <>
                                    <Card.Title >
                                        <Row className='d-flex justify-content-md-center align-items-center '>
                                            <Col xs={12}>
                                                <img  className='Service-img' src={staper} alt="Service" />
                                            </Col>
                                            {/* <Col  xs={12} md={4} className="about-us-col col-ser text-center " >
                                                    <span>1</span>
                                            </Col>
                                            <Col  xs={12} md={4} className="about-us-col col-ser text-center " >
                                                    <span>2</span>
                                            </Col>
                                            <Col  xs={12} md={4} className="about-us-col col-ser text-center" >
                                                    <span>3</span>
                                            </Col> */}
                                        </Row>
                                    </Card.Title>
                                    <Card.Title style={{marginBottom: "20px",paddingLeft: "35px"}}>
                                        <Row className='justify-content-md-center'>
                                            <Col  xs={12} md={4} className="about-us-col col-ser" >
                                                <Row className='pb-3'>
                                                    <span className='home-aboutus'>{t('Explore')}</span>
                                                </Row>
                                                <Row>
                                                    <span className='home-content'>{t('Check out our suite of software solutions.')}</span>
                                                </Row>
                                            </Col>
                                            <Col  xs={12} md={4} className="about-us-col col-ser">
                                                <Row className='pb-3'>
                                                    <span className='home-aboutus'>{t('Connect')}</span>
                                                </Row>
                                                <Row>
                                                    <span className='home-content'>{t('Schedule a consultation with our experts.')}</span>
                                                </Row>        
                                            </Col>
                                            <Col  xs={12} md={4} className="about-us-col col-ser">
                                                <Row className='pb-3'>
                                                    <span  className='home-aboutus'>{t('Innovate')}</span>
                                                </Row>
                                                <Row>
                                                    <span className='home-content'>{t('Unlock your business potential with DCM.')}</span>
                                                </Row>     
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                </>
                            ) : (
                                <>
                                    <Card.Title style={{marginBottom: "20px"}}>
                                        <Row className='justify-content-md-center'>
                                            <Col  xs={12} md={4} className="mt-3 pb-4 col-ser" >
                                                <Row>
                                                    <Col xs={1} md={2}>
                                                        <i className="bi bi-1-square-fill home-aboutus-number"></i>
                                                    </Col>
                                                    <Col xs={11} md={10} className='home-aboutus-number-descript'> 
                                                        <Row className='pb-2'>
                                                            <span className='home-aboutus'>{t('Explore')}</span>
                                                        </Row>
                                                        <Row>
                                                            <span className='home-content'>{t('Check out our suite of software solutions.')}</span>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col  xs={12} md={4} className="pb-4 col-ser">
                                                <Row>
                                                    <Col xs={1} md={2}>
                                                        <i className="bi bi-2-square-fill home-aboutus-number"></i>
                                                    </Col>
                                                    <Col xs={11} md={10} className='home-aboutus-number-descript'> 
                                                        <Row className='pb-2'>
                                                            <span className='home-aboutus'>{t('Connect')}</span>
                                                        </Row>
                                                        <Row>
                                                            <span className='home-content'>{t('Schedule a consultation with our experts.')}</span>
                                                        </Row>  
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col  xs={12} md={4} className="pb-4 col-ser">
                                                <Row>
                                                    <Col xs={1} md={2}>
                                                        <i className="bi bi-3-square-fill home-aboutus-number"></i>
                                                    </Col>
                                                    <Col xs={11} md={10} className='home-aboutus-number-descript'> 
                                                        <Row className='pb-2'>
                                                            <span  className='home-aboutus'>{t('Innovate')}</span>
                                                        </Row>
                                                        <Row>
                                                            <span className='home-content'>{t('Unlock your business potential with DCM.')}</span>
                                                        </Row>     
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                </>
                            )
                        }
                        <Button 
                            className='learnmore-btn' 
                            variant="primary" 
                            size="lg" 
                            style={{ marginTop: '1%'}}
                            onClick={() => navigate('/contact-us')}
                            >
                            {t('Contact Us')}
                        </Button>
                       
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
    );
}

export default Services;