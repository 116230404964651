import React from 'react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS } from './event-utils'
import { Modal } from "react-bootstrap";
import './TaxationCalendar.css';

export default class TaxationCalendar extends React.Component {

  state = {
    modal: false,
    currentEvents: [],
    dateENG: '',
    dateTH: '',
    title: '',
    description: '',
    eventBackgroundColor:''
  }

  toggle = (dateFormat, dateFormatTH, title, description, eventBackgroundColor) => {
    this.setState({ 
        modal: !this.state.modal,
        dateENG: dateFormat,
        dateTH: dateFormatTH,
        title,
        description,
        eventBackgroundColor
    });
  };

  handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault();

    if(clickInfo.event.extendedProps.description){
        const date = clickInfo.event.start;
        const title = clickInfo.event.title;
        const description = clickInfo.event.extendedProps.description;
        const dateFormat = date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' });
        const dateFormatTH = date.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' });

        this.toggle(dateFormat, dateFormatTH , title, description);
    }
  }


  render() {
    return (
      <div className="tax-calendar-container" id="calendar">
          <FullCalendar
            height="auto"
            contentHeight="auto"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
          />
        <Modal
          show={this.state.modal}
          onHide={this.toggle}
          className="event-modal"
          centered
          size="xl"
        >
          <Modal.Header closeButton className="event-header">
            {this.state.dateTH} / {this.state.dateENG}
          </Modal.Header>
          <Modal.Body className="event-body">
            <h5>{this.state.title}</h5>
            <p className="event-modal-description">{this.state.description}</p>
          </Modal.Body>
        </Modal>
      </div>
    )
  }

  handleEvents = (events) => {
    this.setState({
      currentEvents: events
    })
  }

}

function renderEventContent(eventInfo) {
    return (
      <>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }